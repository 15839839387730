import React, { useState, useEffect } from "react";
import { getCategories } from "../services/categoryService";
import { Link } from "react-router-dom";
import "../css/CategoryList.css";
import { Helmet } from "react-helmet-async";

const CategoryList = ({ isHomePage }) => {
  const [categories, setCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const fetchedCategories = await getCategories();
        setCategories(fetchedCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
        setError("Не удалось загрузить категории. Попробуйте снова позже.");
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return <div>Загрузка...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="category-list-container">
      {!isHomePage && (
        <Helmet>
          <title>Категории | Zoorge - приватные читы</title>
          <meta
            name="description"
            content="Ознакомьтесь с нашими категориями приватных читов для различных игр. На Zoorge вы найдете лучшие решения для повышения вашего игрового опыта."
          />
        </Helmet>
      )}
      <h2 className="category-list-title">Категории</h2>
      <input
        type="text"
        placeholder="Поиск по категориям"
        value={searchTerm}
        onChange={handleSearchChange}
        className="search-input"
      />
      <ul className="category-list-ul">
        {filteredCategories.map((category) => (
          <li key={category._id} className="category-list-li">
            <Link
              to={`/category/${category.name}`}
              className="category-list-link"
            >
              {category.imageLink ? (
                <div
                  className="background-image"
                  style={{ backgroundImage: `url(${category.imageLink})` }}
                ></div>
              ) : (
                <div className="background-image-placeholder"></div>
              )}
              <div className="product-info glass">
                <h3 className="category-name">{category.name}</h3>
                <div className="count-link">
                  <p className="product-count">
                    Читов: ({category.productCount})
                  </p>
                  <p className="button-link">Перейти</p>
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CategoryList;
