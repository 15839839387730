const API_URL = "https://crosshack.store/api/products";

export const getProducts = async () => {
  const response = await fetch(API_URL);
  if (!response.ok) {
    throw new Error("Failed to fetch products");
  }
  return response.json();
};

export const getProduct = async (id) => {
  const response = await fetch(`${API_URL}/${id}`);
  if (!response.ok) {
    throw new Error(`Failed to fetch product ${id}`);
  }
  return response.json();
};

export const getCategoryByProductId = async (productId) => {
  try {
    const response = await fetch(`${API_URL}/${productId}/category`);
    if (!response.ok) {
      throw new Error("Failed to fetch category for product");
    }
    const category = await response.json();
    return category;
  } catch (error) {
    console.error("Error fetching category:", error);
    return null;
  }
};

export const getProductByCategoryAndName = async (
  categoryName,
  productName
) => {
  try {
    const response = await fetch(
      `${API_URL}/productByName?categoryName=${encodeURIComponent(
        categoryName
      )}&productName=${encodeURIComponent(productName)}`
    );

    if (!response.ok) {
      throw new Error(
        `Failed to fetch product '${productName}' in category '${categoryName}'`
      );
    }

    return response.json();
  } catch (error) {
    console.error("Error fetching product by category and name:", error);
    throw error;
  }
};

export const getProductsByCategoryName = async (categoryName) => {
  const response = await fetch(`${API_URL}/category/${categoryName}`);
  if (!response.ok) {
    throw new Error(`Failed to fetch products for category ${categoryName}`);
  }
  return response.json();
};

export const getCategories = async () => {
  const response = await fetch(`${API_URL}/categories`);
  if (!response.ok) {
    throw new Error("Failed to fetch categories");
  }
  return response.json();
};
