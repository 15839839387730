import React, { useState, useEffect } from "react";
import "../css/Carousel.css";

const Carousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const goToPrevious = () => {
    console.log("Going to previous slide");
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNext = () => {
    console.log("Going to next slide");
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToSlide = (index) => {
    console.log(`Going to slide ${index}`);
    setCurrentIndex(index);
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape" && isFullscreen) {
      toggleFullscreen();
    } else if (event.key === "ArrowLeft") {
      goToPrevious();
    } else if (event.key === "ArrowRight") {
      goToNext();
    }
  };

  useEffect(() => {
    if (isFullscreen) {
      window.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isFullscreen]);

  const handleContextMenu = (event) => {
    event.preventDefault();
  };

  return (
    <div className={`carousel ${isFullscreen ? "fullscreen" : ""}`}>
      {isFullscreen && (
        <button className="carousel-close" onClick={toggleFullscreen}>
          &times;
        </button>
      )}
      <div className="carousel-inner">
        <div className="carousel-image-wrapper">
          <img
            src={images[currentIndex]}
            alt={`Slide ${currentIndex}`}
            className="carousel-image"
            onClick={toggleFullscreen}
            onContextMenu={handleContextMenu}
          />
        </div>
        <button className="carousel-control left" onClick={goToPrevious}>
          &#10094;
        </button>
        <button className="carousel-control right" onClick={goToNext}>
          &#10095;
        </button>
      </div>
      <div className="carousel-thumbnails">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Thumbnail ${index}`}
            className={`carousel-thumbnail ${
              index === currentIndex ? "active" : ""
            }`}
            onClick={() => goToSlide(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
