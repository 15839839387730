import React from "react";
import "../css/Agreement.css";
import { Helmet } from "react-helmet-async";

const Agreement = () => {
  return (
    <div className="agreement-container">
      <Helmet>
        <meta
          name="description"
          content={`Ознакомьтесь с соглашением на Zoorge. Мы предлагаем лучшие решения для повышения вашего игрового опыта.`}
        />
        <meta property="og:title" content="Соглашение | Zoorge" />
      </Helmet>
      <div className="container">
        <h1>Соглашение</h1>
        <h2>Возврат средств:</h2>
        <p>
          1.1. Мы не принимаем возвраты или обмены, если вы купили неподходящий
          продукт.
        </p>
        <p>
          1.2. Возврат не осуществляется, если программа временно или бессрочно
          приостановила работу.
        </p>
        <p>
          1.3. Гарантированный возврат возможен только если программа не
          запустилась с первого раза.
        </p>
        <p>
          1.4. Компенсация предоставляется исключительно по нашему усмотрению.
        </p>
        <p>
          1.5. Если программа заблокирована (что вы должны знать), возврат
          средств не производится.
        </p>

        <h2>Попытки взлома:</h2>
        <p>
          2.1. При попытке взлома лаунчера любой программы, подписка будет
          заблокирована без возврата средств.
        </p>

        <h2>Прочее:</h2>
        <p>
          3.1. Если ваш HWID изменился, сброс HWID ключа платный, оплата через
          продавца.
        </p>
        <p>
          3.2. Использование программы в компьютерных клубах и подобных
          заведениях не поддерживается, помощь не предоставляется.
        </p>

        <h2>О соглашении:</h2>
        <p>• Покупая программу, вы принимаете условия этого соглашения.</p>
        <p>• Соглашение может быть изменено в любое время.</p>
        <p>• Незнание соглашения не освобождает от ответственности.</p>
        <p>
          • Незнание изменений в соглашении не освобождает от ответственности.
        </p>
      </div>
    </div>
  );
};

export default Agreement;
