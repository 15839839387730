import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import HomePage from "./components/HomePage";
import ProductPage from "./components/ProductPage";
import CategoryList from "./components/CategoryList";
import CategoryPage from "./components/CategoryPage";
import NotFound from "./components/NotFound";
import Agreement from "./components/Agreement";
import "./index.css";

const App = () => {
  const [error, setError] = useState("");
  const [navbarHeight, setNavbarHeight] = useState(0);
  const navbarRef = useRef(null);

  useEffect(() => {
    if (navbarRef.current) {
      setNavbarHeight(navbarRef.current.offsetHeight);
    }
  }, []);

  return (
    <HelmetProvider>
      <Router>
        <div style={{ marginTop: `${navbarHeight}px` }}>
          <Navbar ref={navbarRef} />
          {error && <p style={{ color: "red" }}>{error}</p>}
          <div className="app-container">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/categories" element={<CategoryList />} />
              <Route
                path="/category/:categoryName"
                element={<CategoryPage />}
              />
              <Route
                path="/category/:categoryName/:productName"
                element={<ProductPage />}
              />
              <Route path="*" element={<NotFound />} />
              <Route path="/agreement" element={<Agreement />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
};

export default App;
