import React from "react";
import "../css/Footer.css";
const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section about">
          <h2>О нас</h2>
          <p>
            Zoorge предлагает лучшие читы и инструменты для вашего игрового
            опыта. Мы стремимся предоставлять высококачественные продукты и
            первоклассную поддержку для всех наших пользователей.
          </p>
        </div>
        <div className="footer-section links">
          <h2>Ссылки</h2>
          <ul>
            <li>
              <a href="/categories">Категории</a>
            </li>
            <li>
              <a href="/support">Поддержка</a>
            </li>
            <li>
              <a href="/agreement">Соглашение</a>
            </li>
          </ul>
        </div>
        <div className="footer-section contact">
          <h2>Контакты</h2>
          <p>
            Telegram:
            <a href="https://t.me/ZoorgeSupportBot"> @ZoorgeSupportBot</a>
          </p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Zoorge.ru</p>
      </div>
    </footer>
  );
};

export default Footer;
