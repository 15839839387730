import React, { forwardRef } from "react";
import { Link } from "react-router-dom";
import "../css/Navbar.css";

const Navbar = forwardRef((props, ref) => {
  return (
    <nav className="navbar" ref={ref}>
      <Link to="/" className="logo">
        <img
          className="logo-img"
          src={`${process.env.PUBLIC_URL}/logo.png`}
          alt="Logo"
        />
        <h3>zoorge</h3>
      </Link>
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link to="/" className="nav-link">
            Главная
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/categories" className="nav-link">
            Категории
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/agreement" className="nav-link">
            Соглашение
          </Link>
        </li>
      </ul>
    </nav>
  );
});

export default Navbar;
