import React from "react";
import "../css/HomePage.css";
import CategoryList from "./CategoryList";
import { Helmet } from "react-helmet-async";

const HomePage = () => {
  return (
    <div className="home-page">
      <Helmet>
        <title>Главная | Zoorge - приватные читы</title>
        <meta
          name="description"
          content="Добро пожаловать на Zoorge! Мы предлагаем лучшие приватные читы для различных игр. Повышайте ваш игровой опыт с нашими эксклюзивными решениями."
        />
      </Helmet>
      <div className="welcome-section">
        <h1>Добро пожаловать на Zoorge.ru!</h1>
        <p>
          Мы предлагаем лучшие читы и инструменты для вашего игрового опыта.
          Найдите то, что вам нужно, и получите максимальное удовольствие от
          игры.
        </p>
      </div>
      <CategoryList isHomePage />
    </div>
  );
};

export default HomePage;
