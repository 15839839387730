import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { getProductsByCategoryName } from "../services/productService";
import "../css/CategoryPage.css";
import { Helmet } from "react-helmet-async";

const CategoryPage = () => {
  const { categoryName } = useParams();
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTags, setSelectedTags] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchCategoryProducts = async () => {
      try {
        const data = await getProductsByCategoryName(categoryName);
        setProducts(data);
        setFilteredProducts(data);
      } catch (error) {
        console.error("Error fetching category products:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategoryProducts();
  }, [categoryName]);

  useEffect(() => {
    filterProducts();
  }, [selectedTags, searchTerm, products]);

  const handleTagChange = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((t) => t !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const filterProducts = () => {
    let filtered = products;

    if (selectedTags.length > 0) {
      filtered = filtered.filter((product) =>
        selectedTags.every((tag) => product.tags.includes(tag))
      );
    }

    if (searchTerm) {
      filtered = filtered.filter((product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredProducts(filtered);
  };

  const uniqueTags = [...new Set(products.flatMap((product) => product.tags))];

  const getFirstPrice = (purchaseOptions) => {
    if (!purchaseOptions || purchaseOptions.length === 0) return null;
    const firstOption = purchaseOptions[0];
    const priceMatch = firstOption.days.match(/(\d+р)$/);
    return priceMatch ? priceMatch[0] : null;
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <div className="category-page">
      <Helmet>
        <title>Приватные читы для {categoryName} | Zoorge</title>
        <meta
          name="description"
          content={`Ознакомьтесь с приватными читами для ${categoryName} на Zoorge. ${uniqueTags.join(
            ", "
          )}. Мы предлагаем лучшие решения для повышения вашего игрового опыта`}
        />
      </Helmet>
      <h2 className="category-title">Приватные читы для {categoryName}</h2>
      <div className="all-filters">
        <div className="search-container">
          <input
            type="text"
            className="search-input"
            placeholder={"Поиск по названию..."}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="filters-container">
          {uniqueTags.map((tag) => (
            <label key={tag} className="filter-checkbox">
              <input
                type="checkbox"
                checked={selectedTags.includes(tag)}
                onChange={() => handleTagChange(tag)}
              />
              {tag}
            </label>
          ))}
        </div>
      </div>

      <div className="products-container">
        {filteredProducts.map((product) => {
          const firstPrice = getFirstPrice(product.purchaseOptions);
          return (
            <Link
              to={`/category/${categoryName}/${product.name}`}
              key={`product-${product._id}`}
              className="product-link"
            >
              <div className="product-card">
                <div className="tags">
                  {product.tags.map((tag, index) => (
                    <span key={index} className="tag">
                      {tag}
                    </span>
                  ))}
                </div>
                <div
                  className="background-image"
                  style={{ backgroundImage: `url(${product.images[0]})` }}
                ></div>
                <div className="glass product-info">
                  <h3 className="product-name">{product.name}</h3>
                  <div className="price-open">
                    {firstPrice && (
                      <p className="product-price">От {firstPrice}</p>
                    )}
                    <p className="cheat-open">Подробнее</p>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default CategoryPage;
