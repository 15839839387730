const API_URL = "https://crosshack.store/api/categories";

export const getCategories = async () => {
  const response = await fetch(API_URL);
  if (!response.ok) {
    throw new Error("Failed to fetch categories");
  }
  return response.json();
};

export const getCategory = async (id) => {
  const response = await fetch(`${API_URL}/${id}`);
  if (!response.ok) {
    throw new Error(`Failed to fetch category ${id}`);
  }
  return response.json();
};

export const addCategory = async (category) => {
  const response = await fetch(API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(category),
  });
  if (!response.ok) {
    throw new Error("Failed to add category");
  }
  return response.json();
};

export const updateCategory = async (id, category) => {
  const response = await fetch(`${API_URL}/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`, // Assuming token is stored in localStorage
    },
    body: JSON.stringify(category),
  });
  if (!response.ok) {
    throw new Error(`Failed to update category ${id}`);
  }
  return response.json();
};

export const deleteCategory = async (id) => {
  const response = await fetch(`${API_URL}/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`, // Assuming token is stored in localStorage
    },
  });
  if (!response.ok) {
    throw new Error(`Failed to delete category ${id}`);
  }
  return response.json();
};
